import { Injectable, inject, signal } from '@angular/core'
import { LogLevel, LogService } from './log.service'
import { EnvironmentService } from './environment.service'
import { ModalService } from './modal.service'


export enum AppInitStatus {
  Preparing = 'preparing',
  Loading = 'loading',
  LoadingAdmin = 'loadingAdmin',
  Success = 'success',
  SuccessAdmin = 'successAdmin',
  NotPublic = 'notPublic',
  Error = 'error',
}

@Injectable()
export class AppInitService {

  private _logger = inject(LogService)
  private _envService = inject(EnvironmentService)
  private _modalService = inject(ModalService)

  status = signal(AppInitStatus.Loading)

  async init(): Promise<void> {
    // Any initialization that should happen for both Admin mode and User mode
    this.status.set(AppInitStatus.Preparing)
    this._logger.init(this._envService.get().logLevel ?? LogLevel.Off)
    this._logger.debug(`AppInitService.init()`)
    this._modalService.init()
    return Promise.resolve()
  }

}
